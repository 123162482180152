import { createRoutesFromElements, Route } from "react-router-dom";

import Packages from "./components/Packages";
import About from "./components/About";
import RootLayout from "./components/Root";
import Home from "./components/Home";
import WhyChoseUs from "./components/WhyChoseUs";
import TermsAndConditions from "./components/TermsAndConditions";
import Contact from "./components/Contact";

const routeDefinitions = createRoutesFromElements(
  <>
    <Route path="/" element={<RootLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/packages" element={<Packages />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<WhyChoseUs />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/t&c" element={<TermsAndConditions />} />
    </Route>
  </>
);

export default routeDefinitions;
