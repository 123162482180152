import { Box, Typography, Card } from "@mui/material";
import React from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import data from "../data.json";
import CountUp from "react-countup";

const About = () => {
  const reviews = data.reviews;
  const awards = [
    "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/award1.png",
    "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/award2.png",
    "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/award3.png",
    "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/award4.png",
    "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/award5.png",
    "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/award6.png",
    "https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/award7.png",
  ];
  return (
    <Box>
      <Box
        sx={{
          margin: "5px",
          padding: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}>
        <Box
          sx={{
            width: "100%",
            minHeight: "200px",
            backgroundColor: "#F9D851",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            borderRadius: "10px",
          }}>
          <Box>
            <img
              src="https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/G5S.png"
              alt="logo"
              height={"100px"}
            />
          </Box>
          <Box>
            <Typography variant="h6"  >
              <CountUp end={1000} duration={5} />+
            </Typography>
            <Typography variant="body1">5 Star Reviews</Typography>
          </Box>
          <Swiper
            spaceBetween={5}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 2,
              },
            }}
            modules={[Autoplay]}>
            {reviews.map((review, index) => (
              <SwiperSlide key={index}>
                <Card
                  sx={{
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    boxShadow: 5,
                    justifyContent: "center",
                    maxWidth: "700px",
                    margin: "10px",
                    flexDirection: { xs: "column", md: "row" },
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "center",
                      width: "100%",
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: { xs: "100%", md: "30%" },
                        padding: "10px",
                      }}>
                      <img
                        src={review.image}
                        alt="customer"
                        style={{
                          height: "150px",
                          width: "150px",
                          borderRadius: "10px",
                          marginBottom: "10px",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}>
                        <VerifiedIcon
                          sx={{
                            color: "primary.main",
                            margin: "5px",
                          }}
                        />
                        <Typography variant="body1">
                          Passed in First Attempt
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: { xs: "100%", md: "70%" },
                        padding: "10px",
                      }}>
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "left",
                          paddingLeft: { xs: "0", md: "10px" },
                          paddingTop: { xs: "10px", md: "0" },
                          fontSize: { xs: "14px", md: "16px" },
                        }}>
                        {review.review}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Awards
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                sm: "repeat(4, 1fr)",
                md: "repeat(7, 1fr)",
              },
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}>
            {awards.map((award, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "10px",
                }}
                key={index}>
                <img
                  src={award}
                  alt={`award ${index + 1}`}
                  style={{
                    height: "200px",
                    width: "150px",
                    padding: "10px",
                    border: "2px solid #F9D851",
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}>
          <Box sx={{ flex: 1, padding: "10px" }}>
            <Card
              sx={{
                margin: "20px",
                padding: "20px",
                maxWidth: "900px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                textAlign: "left",
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "10px",
                boxShadow: 3,
                backgroundColor: "#f9f9f9",
              }}>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                We prioritize your safety and confidence on the road. With over
                20 years of experience, our expert instructors are dedicated to
                providing personalized, comprehensive driving education.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                Our curriculum includes:
              </Typography>
              <Box sx={{ paddingLeft: "20px" }}>
                <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                  <strong>Customized Lessons:</strong> Tailored to meet the
                  unique needs of each student, ensuring you progress at your
                  own pace.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                  <strong>Certified Instructors:</strong> Our team is composed
                  of highly qualified, DMV-certified instructors with extensive
                  experience.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                  <strong>Modern Fleet:</strong> Our vehicles are equipped with
                  the latest safety features and are regularly maintained for
                  optimal performance.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                  <strong>Flexible Scheduling:</strong> We offer lessons at
                  various times to accommodate your busy schedule, including
                  evenings and weekends.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                  <strong>Affordable Packages:</strong> Competitive pricing with
                  no hidden fees, and various packages to fit different budgets.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                  <strong>Comprehensive Training:</strong> From basic driving
                  skills to advanced defensive driving techniques, our programs
                  cover everything you need to become a skilled driver.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                  <strong>High Success Rate:</strong> Our students have a high
                  pass rate on their first attempt at the driving test,
                  reflecting our commitment to quality instruction.
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{ marginTop: "20px", fontWeight: "bold" }}>
                Join CITY DRIVING SCHOOL today and take the first step towards
                becoming a confident, safe, and responsible driver.
              </Typography>
            </Card>
          </Box>
          <Box sx={{ flex: 1, padding: "10px" }}>
            <img
              src="https://citydrivingschool.s3.ap-southeast-2.amazonaws.com/instructor.png"
              alt="Instructor"
              style={{
                width: "40%",
                minHeight: "200px",
                borderRadius: "10px",
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Your Instructor: Mr. Mustafa
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
