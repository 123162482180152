import React from "react";
import { Box, Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Box
      sx={{
        margin: "30px",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        border: "1px solid black",
        justifyContent: "center",
        alignItems: "start",
        gap: "20px",
      }}
      color="primary">
      <Typography variant="h4">Terms and Conditions</Typography>
      <Typography variant="h5">
        1. Package lessons must be scheduled Monday to Friday between 9:00 AM
        and 4:00 PM, and the car must be provided by the student.
      </Typography>
      <Typography variant="h5">
        2. Any changes to booked lesson times or dates must be given with
        24-hour notice. Fees are not refunded, nor can the time be used for
        another lesson.
      </Typography>
      <Typography variant="h5">
        3. All lesson fees must be paid in advance, unless otherwise determined
        at the driving instructor's discretion.
      </Typography>
      <Typography variant="h5">
        4. Late arrival at the pick-up point will count as lost time. Covering
        lost time is at the discretion of the instructor.
      </Typography>
      <Typography variant="h5">
        5. It is the student's duty to carry their driving license at all times
        during the driving lesson.
      </Typography>
      <Typography variant="h5">
        6. If the student forgets their documents on the test day and the
        driving test is canceled, the full fee will be charged and will not be
        refunded.
      </Typography>
      <Typography variant="h5">
        7. All package lessons must be completed within six months.
      </Typography>
      <Typography variant="h5">
        8. Fees may vary with driving test time, distance, and lesson hours.
      </Typography>
    </Box>
  );
};

export default TermsAndConditions;
