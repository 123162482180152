import React from "react";
import { Box, Typography, Card } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import SpeedIcon from "@mui/icons-material/Speed";
import StarIcon from "@mui/icons-material/Star";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import SchoolIcon from "@mui/icons-material/School";

const WhyChoseUs = () => {
  const services = [
    {
      Title: "BEST SERVICE",
      Icon: <StarIcon />,
      Description:
        "Our competitive prices are among the best in the industry. Our selection of packages will tailor for any situation",
    },
    {
      Title: "FRIENDLY SERVICE",
      Icon: <LoyaltyIcon />,
      Description:
        "We believe in making sure our customers have pleasant experience",
    },
    {
      Title: "AUTOMATIC AND MANUAL",
      Icon: <ElectricCarIcon />,
      Description: "Choose Automatic or Manual, Don't worry we got you covered",
    },
    {
      Title: "VERSATILE INSTRUCTOR",
      Icon: <AltRouteIcon />,
      Description:
        "An experienced and patient instructor, accredited by RMS, ADTA. We teach with ease, fun, no screaming.",
    },
    {
      Title: "FIRST ATTEMPT PASS",
      Icon: <SchoolIcon />,
      Description:
        "We will prepare you well and give all the knowledge, skills to pass first attempt.",
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          margin: "10px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography variant="h4">Why Us?</Typography>
        <Box
          sx={{
            padding: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}>
          <Card
            sx={{
              margin: "10px",
              padding: "20px",
              display: "flex",
              maxWidth: "500px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid",
              borderColor: "primary.main",
              borderRadius: "20px",
              boxShadow: 7,
            }}>
            <BoltIcon sx={{ fontSize: "50px" }} />
            <Typography variant="h6">FAST TRACK YOUR LICENCE!</Typography>
            <p>
              1 Hour Lesson = 3 Logbook Hours With Us. * maximum of 10 driving
              lesson hours to be recorded as 30 hours in the logbook
            </p>
          </Card>
          <Card
            sx={{
              margin: "10px",
              padding: "20px",
              display: "flex",
              maxWidth: "500px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid",
              borderColor: "primary.main",
              borderRadius: "20px",
              boxShadow: 7,
            }}>
            <SpeedIcon sx={{ fontSize: "50px" }} />
            <Typography variant="h6">PRE DRIVING TEST ASSESSMENT</Typography>
            <p>
              The pre-test assessment can be done in the testing area, so you
              will be familiar with this on the day of the test.
            </p>
          </Card>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "10px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography variant="h4">What You Get?</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1rem",
          }}>
          {services.map((service, i) => (
            <Card
              key={i}
              sx={{
                margin: "10px",
                padding: "20px",
                display: "flex",
                width: "350px",
                height: "200px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 7,
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "20px",
              }}>
              <Typography variant="h6">{service.Title}</Typography>
              {service.Icon}
              <p>{service.Description}</p>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default WhyChoseUs;
