import { Box, Typography } from "@mui/material";
import React from "react";
import { styled, keyframes } from "@mui/system";

const scrollAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;

const ScrollingTypography = styled(Typography)({
  display: "inline-block",
  whiteSpace: "nowrap",
  animation: `${scrollAnimation} 50s linear infinite`,
});

const LicConversionText = () => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        padding: "5px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        overflow: "hidden", // Hide the overflow
      }}>
      <ScrollingTypography variant="h5">
        We Can Help You Convert Your Driving License from India, Pakistan, and
        Other Countries to a New South Wales (NSW), Australian License
      </ScrollingTypography>
    </Box>
  );
};

export default LicConversionText;