import "./App.css";
import { ThemeProvider, createTheme, Box, CssBaseline } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routeDefinitions from "./routeDefinations";

const Router = createBrowserRouter(routeDefinitions);
const theme = createTheme({
  palette: {
    primary: {
      main: "#F9D851",
      contrastText: "#000",
    },
    background: {
      default: "#FFF",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="App">
        <RouterProvider router={Router}></RouterProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
